/* FullScreenImage.css */
.full-screen-image-container {
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    position: relative;
    min-height: 445px;
    height: 100%;
  }
  
  .full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
  
  .image {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    display: block;
    transition: all 0.3s ease-in-out;
  }
  
  /* Make the image responsive */
  @media screen and (max-width: 768px) {
    .image.full-screen-image {
      height: 100%;
      width: auto;
    }
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
  }
  
  /* Make the close button responsive */
  @media screen and (max-width: 768px) {
    .close-button {
      top: 10px;
      right: 10px;
      font-size: 16px;
      padding: 3px 8px;
    }
  }
  
  /* Add movement to the image */
  .image.full-screen-image {
    transform: scale(1.2); /* Example: Scale the image up by 20% on full screen */
    transition: transform 0.5s ease-in-out;
  }
  
  /* You can add more animations or transitions here */
  